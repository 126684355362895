/* UI Breadcrumbs */
.w-breadcrumbs {
    margin: -10px auto 10px;
    max-width: 980px;
}

html:not(.page-mypages) .w-breadcrumbs.is-in-toparea {
    margin: 0;
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
    z-index: 10;
}

.ui-breadcrumbs {
    text-align: center;
    overflow: hidden;
    zoom: 1;
    padding: 0 15px;
}

.ui-breadcrumbs .w-breadcrumbs-list {
    overflow: hidden;
    padding: 0;
    margin-top: 20px;
}

.ui-breadcrumbs .inline-list {
    text-align: left;
}

.ui-breadcrumbs .list-item {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    list-style: none;
    font-size: var(--font-size-caption);
    line-height: 1.43;
    height: 20px;
}

.ui-breadcrumbs .list-item:first-child .item {
    padding-left: 0;
}

.ui-breadcrumbs .active-item,
.ui-breadcrumbs .item {
    padding: 0 8px;
    height: 20px;
    display: block;
    position: relative;
    white-space: nowrap;
}

html:not(.page-mypages) .is-in-toparea .ui-breadcrumbs .active-item,
html:not(.page-mypages) .is-in-toparea .ui-breadcrumbs .item {
    color: var(--common-white);
}

.ui-breadcrumbs .item {
    opacity: 0.4;
}

html:not(.page-mypages) .is-in-toparea .ui-breadcrumbs .item {
    opacity: 0.7;
}

.ui-breadcrumbs .item:hover {
    opacity: 1;
    color: var(--common-black);
}

html:not(.page-mypages) .is-in-toparea .ui-breadcrumbs .item:hover {
    color: var(--common-white);
}

.ui-breadcrumbs .active-item .item-name {
    color: var(--common-black);
}

html:not(.page-mypages) .is-in-toparea .ui-breadcrumbs .active-item .item-name {
    color: var(--common-white);
}

.ui-breadcrumbs .item-name {
    display: inline-block;
    height: 20px;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui-breadcrumbs .item-name:first-letter {
    text-transform: capitalize;
}

.ui-breadcrumbs .item::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    top: 50%;
    right: -3px;
    margin-top: -3px;
    border-top: 1px solid var(--grey-300);
    border-right: 1px solid var(--grey-300);
    -webkit-transform: rotate(45deg) translate3d(0,0,0);
    -ms-transform: rotate(45deg) translate3d(0,0,0);
    transform: rotate(45deg) translate3d(0,0,0);
    -webkit-backface-visibility: hidden;
}

html:not(.page-mypages) .is-in-toparea .ui-breadcrumbs .item::before {
    border-color: var(--common-white);
}

.ui-breadcrumbs .active {
    cursor: default;
}

@media screen and (min-width: 768px) {
    .n-content .ui-breadcrumbs .inline-list {
        width: 100%;
    }
}

/* BP1 + BP2 */

@media screen and (max-width: 767px) {
    .w-breadcrumbs {
        display: none;
    }
}

/* BP3 + BP4 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .ui-breadcrumbs .inline-list {
        width: 60%;
    }

    .n-content > .w-breadcrumbs {
        margin-top: 15px;
    }
}

/* BP5 */
@media screen and (min-width: 1025px) {
    .ui-breadcrumbs {
        padding: 0 30px;
    }

    .page-mypages .ui-breadcrumbs {
        padding: 0;
        margin-bottom: 15px;
    }

    .ui-breadcrumbs .inline-list {
        width: 70%;
    }
}

@media screen and (min-width: 1200px) {
    .ui-breadcrumbs {
        padding: 0 60px;
    }
}